<template>
  <div v-if="$slots.default" class="d-flex align-center">
    <v-divider class="flex-grow-1" />
    <div class="px-4">
      <slot />
    </div>
    <v-divider class="flex-grow-1" />
  </div>
  <v-divider v-else />
</template>
